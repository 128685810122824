export function isValidUrl(url: string): boolean {
  try {
    new URL(url);
  } catch (error) {
    return false;
  }

  return true;
}

export function isDomainReachable(url: string): boolean {
  return false;
}
