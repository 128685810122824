import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

const Footer = () => (
  <Container fluid={true} className="bg-dark">
    <footer className="footer mt-auto py-3 bg-dark text-white" style={{ color: '#000' }}>
      <Row>
        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 3 }} className="mb-3">
          <h5 className="mb-4">PentestToolLite.com</h5>
          <p style={{ fontSize: '0.9rem' }}>Analyze your webpage and check most common server setting issues.</p>
        </Col>
        <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 3, offset: 3 }} lg={{ span: 2, offset: 5 }}>
          <h6>Links</h6>
          <hr className="mb-4 mt-0 d-inline-block mx-auto" style={{ width: '60px', backgroundColor: '#7c4dff', height: '2px' }}></hr>
          <p><Link className="nav-link text-light" to="/">Home</Link></p>
        </Col>
        <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 3 }} lg={{ span: 2 }}>
          <h6>Contact</h6>
          <hr className="mb-4 mt-0 d-inline-block mx-auto" style={{ width: '60px', backgroundColor: '#7c4dff', height: '2px' }}></hr>
          <p><a className="nav-link text-light" href="mailto:matej@jellus.it">matej@jellus.it</a></p>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <hr />
          <p className="text-center text-light mb-0" style={{color: '#000', fontSize: '0.7rem'}}><a href="https://juffalow.com" target="_blank" rel="noopener noreferrer" style={{ color: '#fff', textDecoration: 'none' }}>Matej <span style={{ color: 'red' }}>'juffalow'</span> Jelluš</a> | 2021 - 2024</p>
        </Col>
      </Row>      
    </footer>
  </Container>
);

export default React.memo(Footer);
