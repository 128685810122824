import { get, post } from './client';

export async function createTest(url: string): Promise<any> {
  return post(`${process.env.REACT_APP_API_URL}/test`, { url });
}

export async function getResult(id: string): Promise<any> {
  return get(`${process.env.REACT_APP_API_URL}/test/${id}`).then((response) => response.data.test);
}

export async function getDNS(domain: string): Promise<any> {
  return get(`${process.env.REACT_APP_API_URL}/dns?domain=${encodeURIComponent(domain)}`).then((response) => response);
}

export async function getLastTests(): Promise<any> {
  return get(`${process.env.REACT_APP_API_URL}/test`).then((response) => response.data.tests);
}
