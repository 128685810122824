import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { useParams } from 'react-router-dom';
import Result from './report/Result';
import SEO from '../components/SEO';
import { getResult } from '../api/services';

const Report: React.FC = () => {
  const params = useParams();
  const [ test, setTest ] = useState<any>(null);
  const [ statusFilter, setStatusFilter ] = useState<string[]>([]);

  const toggleStatusFilter = (event: any): void => {
    const status: string = event.target.value;
    if (statusFilter.includes(status)) {
      setStatusFilter(statusFilter.filter((value: string) => value !== status));
    } else {
      setStatusFilter([...statusFilter, status]);
    }
  }; 

  useEffect(() => {
    getResult(params.id as string).then((response: any) => setTest(response));
  }, [ params.id ]);

  useEffect(() => {
    const interval = setInterval(() => {
      getResult(params.id as string).then((response: any) => {
        setTest(response);
        if (response.status !== 'running') {
          clearInterval(interval);
        }
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [ params.id ])

  return (
    <SEO title="Report" description="">
      <Container>
        <Row>
          <Col>
            {
              test !== null ? (
                <>
                  <h1>Report for {(new URL(test.url)).hostname}</h1>
                  <ListGroup variant="flush" className="mt-4">
                    <ListGroup.Item><b>URL</b>: <a href={test.url} target="_blank" rel="noopener noreferrer">{test.url}</a></ListGroup.Item>
                    <ListGroup.Item><b>Requested at</b>: {(new Date(Date.parse(test.createdAt))).toString()}</ListGroup.Item>
                    <ListGroup.Item><b>Status</b>: &nbsp;
                      {
                        test.status === 'running' && ( <Badge bg="secondary">{test.status}</Badge> )
                      }
                      {
                        test.status === 'error' && ( <Badge bg="danger">{test.status}</Badge> )
                      }
                      {
                        test.status === 'done' && ( <Badge bg="success">{test.status}</Badge> )
                      }
                    </ListGroup.Item>
                  </ListGroup>
                  <h2 className="mt-4">Results</h2>
                  <Row xs="auto" className="mt-4">
                    <ButtonGroup>
                      <ToggleButton
                        id="success"
                        type="checkbox"
                        variant="outline-success"
                        value="SUCCESS"
                        checked={statusFilter.includes("SUCCESS")}
                        onChange={toggleStatusFilter}
                      >
                        {test.successCount} Success
                      </ToggleButton>
                      <ToggleButton
                        id="warning"
                        type="checkbox"
                        variant="outline-warning"
                        value="WARNING"
                        checked={statusFilter.includes("WARNING")}
                        onChange={toggleStatusFilter}
                      >
                        {test.warningCount} Warning
                      </ToggleButton>
                      <ToggleButton
                        id="critical"
                        type="checkbox"
                        variant="outline-danger"
                        value="ERROR"
                        checked={statusFilter.includes("ERROR")}
                        onChange={toggleStatusFilter}
                      >
                        {test.errorCount} Critical
                      </ToggleButton>
                    </ButtonGroup>
                  </Row>
                </>
              ) : (
                <h1>Report</h1>
              )
            }
          </Col>
        </Row>
        {
          test !== null && test.status === 'done' ? (
            <Row className="mt-4">
              <Col>
                <h3>Security</h3>
                <Result tests={test.result.security.results.filter((result: unknown) => result !== null)} level={1} filteredStatuses={statusFilter} />
              </Col>
            </Row>
          ) : null
        }
        {
          test !== null && test.status === 'done' && 'dns' in test.result && test.result.dns.results.length > 0 ? (
            <Row className="mt-4">
              <Col>
                <h3>DNS</h3>
                <Result tests={test.result.dns.results.filter((result: unknown) => result !== null)} level={1} filteredStatuses={statusFilter} />
              </Col>
            </Row>
          ) : null
        }
        {
          test !== null && test.status === 'done' ? (
            <Row className="mt-4">
              <Col>
                <h3>HTML</h3>
                <Result tests={test.result.html.results.filter((result: unknown) => result !== null)} level={1} filteredStatuses={statusFilter} />
              </Col>
            </Row>
          ) : null
        }
        {
          test !== null && test.status === 'done' && 'seo' in test.result && test.result.seo.results.length > 0 ? (
            <Row className="mt-4">
              <Col>
                <h3>SEO</h3>
                <Result tests={test.result.seo.results.filter((result: unknown) => result !== null)} level={1} filteredStatuses={statusFilter} />
              </Col>
            </Row>
          ) : null
        }
        {
          test !== null && test.status === 'done' && test.result.wordpress.status !== 'SUCCESS' ? (
            <Row className="mt-4">
              <Col>
                <h3>WordPress</h3>
                <Result tests={test.result.wordpress.results.filter((result: unknown) => result !== null)} level={1} filteredStatuses={statusFilter} />
              </Col>
            </Row>
          ) : null
        }
      </Container>
    </SEO>
  );
}

export default Report;
